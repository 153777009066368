@media (min-width: 992px) {
  .w-md-100 {
    width: 100% !important;
  }
}

@media (max-width: 1200px) {
}
@media (max-width: 992px) {
}
@media (max-width: 768px) {
  .main-header {
    .navbar-brand {
      margin-right: 15px;
    }
  }
  .main-footer {
    &__col-title {
      font-size: 28px;
      margin-bottom: 20x;
      text-align: center;
    }

    &__menu {
      text-align: center;
    }

    &__info {
      text-align: center;
      justify-content: center;
    }
  }
  .footer-brand {
    display: block;
    text-align: center;
  }

  .section-hero {
    padding: 0 !important;
    /* The image used */
    background-image: url("../assets/hero.jpg");

    /* Set a specific height */
    // min-height: 720px;

    /* Create the parallax scrolling effect */
    // background-attachment: fixed;
    background-position: -450px center;
    background-repeat: no-repeat;
    background-size: cover;

    // &__content {
    //   padding: 150px 0;
    // }
  }

  .partner-well {
    &:not(:last-child) {
      margin-bottom: 60px;
    }
    &__back {
      padding: 40px;
    }

    &__image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &__overlay-title {
      font-size: 50px;
    }

    &__title {
      font-size: 32px;
      margin-bottom: 30px;
    }
  }
  .partner-rows {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    margin-left: -10px;
    margin-right: -10px;
    &__item {
      width: 50%;
      padding: 0 10px;
      margin-bottom: 20px;
      &:hover {
        transform: translateY(0);
      }
      img {
        padding: 10px 10px;
      }
      &-title {
        font-size: 14px;
        text-align: center;
      }
    }
  }
}
@media (max-width: 576px) {
  .section-hero {
    background-position: -475px center;

    &__content {
      padding: 150px 0;
    }
  }
}
